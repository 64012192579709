import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useMoEngage from "hooks/useMoEngage";
import { getCustomerDetails } from "services/myBuys";
import { MY_PAYMENTS_LISTING_PAGE_EVENTS } from "utils/events";

import { ImageIcons } from "components";

import { OptionsWrapper, StyledButton } from "../styled";

const LedgerDownLoad = ({ eventLocation = "transaction" }) => {
  const tracker = useMoEngage();

  const [showOptions, setShowOptions] = useState(false);
  const [uuId, setUuId] = useState("");

  const { me } = useSelector((state) => state.app);

  useEffect(() => {
    getCustomerDetails(me.customerId).then((res) => setUuId(res?.uuid));
  }, []);

  const getCustomerLedger = (ledgerLength) => {
    const EVENT_PARAMS = {
      // mobile_no: "",
      timestamp: new Date(),
      user_id: me.customerId,
      click_location: eventLocation,
      ledger_length: ledgerLength,
    };
    tracker(MY_PAYMENTS_LISTING_PAGE_EVENTS.LEDGER_DOWNLOAD, EVENT_PARAMS);
    const params = {
      month_count: ledgerLength,
      customer_uuid: uuId,
    };
    const URL = {
      pathname: `${process.env.REACT_APP_VELYNK_URL}customers/generate_ledger_pdf.pdf?`,
      search: new URLSearchParams(params).toString(),
    };

    setShowOptions(false);
    window.open(URL.pathname + URL.search);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <ImageIcons
        name="LedgerIcon"
        style={{ width: "7.5rem" }}
        onClick={() => setShowOptions(!showOptions)}
      />

      {showOptions && (
        <OptionsWrapper>
          <StyledButton
            className="mb-1"
            sx={{
              borderBottom: "0.25px solid gray",
              borderBottomLeftRadius: "initial",
              borderBottomRightRadius: "initial",
            }}
            onClick={() => getCustomerLedger(1)}
          >
            Last Month
          </StyledButton>
          <StyledButton onClick={() => getCustomerLedger(3)}>
            Last 3 Months
          </StyledButton>
        </OptionsWrapper>
      )}
    </div>
  );
};

export default LedgerDownLoad;
