import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, ClickAwayListener } from "@mui/material";
import Loader from "atomic-components/Loader";
import useCutomerDetails from "hooks/useCustomerDetails";
import useMoEngage from "hooks/useMoEngage";
import useTracker from "hooks/useTracker";
import { translate } from "locale/formatter";
import { isEmpty } from "lodash";
import {
  getAllMyPayments,
  getAllSplitPayments,
  getMyBuys,
  getMyPayments,
} from "services/myBuys";
import { findWordInUrl, getStartOfToday } from "utils";
import { path, settings } from "utils/const";
import { formatDateToDDMMYYYY, getDiffDays } from "utils/dateUtils";
import {
  MY_BUYS_LISTING_PAGE_EVENTS,
  MY_PAYMENTS_LISTING_PAGE_EVENTS,
  PAGE_EVENTS,
} from "utils/events";
import { getMandiId, getPreviousPage } from "utils/localStorage";

import CustomTabs from "components/CustomTabs";
import { MY_BUYS_TABS } from "components/CustomTabs/const";
import DateRangepicker from "components/dateRangePicker";
import AlertDialog from "components/Dialog/AlertDialog";
import EmptyScreen from "components/EmptyScreen";
import InfiniteScroll from "components/InfineScroll";
import Text from "components/Text";
import { TEXT_TYPES } from "components/Text/const";

import LedgerDownLoad from "./components/LedgerDownload";
import PurchaseCard from "./components/purchase-card";
import Header from "./my-transactions/Header";
import MyPayments from "./myPayments";
import OutstandingCard from "./OutstandingCard";
import { classes, StyledContainer } from "./styled";

const OFFSET_LIMIT = 15;
const styles = {
  right: 0,
  zIndex: 1,
  position: "absolute",
};

const MyBuys = ({ source: componentSource }) => {
  const { pathname, state } = useLocation();
  const trackUser = useTracker();
  const { me } = useSelector((state) => state.app);
  const tracker = useMoEngage();

  const [dates, setDates] = useState([
    {
      endDate: new Date(),
      startDate: new Date(
        new Date().setDate(new Date().getDate() - (settings.myBuysDiffDays - 1))
      ),
      key: "selection",
    },
  ]);
  const [openDialog, setOpenDialog] = useState(false);
  const [boughtDetails, setBoughtDetails] = useState([]);
  const [openDatepicker, setOpenDatepicker] = useState(false);
  const getSrcPage = getPreviousPage();
  const range = dates[0];
  const [selectedTab, setSelectedTab] = useState(MY_BUYS_TABS.MY_BUYS);
  const [myPaymentList, setMyPaymentList] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [lastRecordReached, setLastRecordReached] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const {
    myBuysWrapper,
    calendarDiv,
    purchaseDateText,
    selectedDateRange,
    calendarBox,
    myPaymentsBox,
    myBuysBox,
  } = classes();
  const pageRefreshedRef = useRef(false);
  const [splitInfo, setSplitInfo] = useState([]);
  const [customerDetails] = useCutomerDetails(me);

  const showLedgerDownloadBtn = pathname.includes("bidder-mybuys");

  const getMyBuysInfo = () => {
    setLoading(true);
    const myBuysPresent = findWordInUrl(pathname, "my-buys");
    const myPaymentsPresent = findWordInUrl(pathname, "my-payments");
    const params = {
      my_buys: true,
      customer_id: me.customerId,
      auction_date: getStartOfToday(range?.startDate),
      to_auction_date: getStartOfToday(range?.endDate),
      ...(myBuysPresent || myPaymentsPresent
        ? { mandi_credit_unit_id: state.mandi_credit_unit_id }
        : {}),
    };
    getMyBuys(params)
      .then(({ responseData }) => {
        setBoughtDetails(responseData || []);
      })
      .finally(() => setLoading(false));
  };

  const getAllSplitPaymentsInfo = (record_type, record_id) => {
    const params = {
      record_type,
      record_id,
    };

    getAllSplitPayments(params).then(({ items }) => {
      setSplitInfo(items || []);
    });
  };
  const getMyPaymentsInfo = () => {
    setLoading(true);
    const params = {
      offset: (currentPage - 1) * OFFSET_LIMIT || 0,
      limit: OFFSET_LIMIT,
      customer_id: me.customerId,
      mandi_credit_unit_id: state.mandi_credit_unit_id,
    };

    if (pathname.includes("bidder-transactions")) {
      getMyPayments({ ...params, mandi_id: getMandiId() })
        .then((res) => {
          if (isEmpty(res.items)) {
            setLastRecordReached(true);
            return;
          }
          setMyPaymentList((prevData) => {
            let lotData = res.items;
            if (isEmpty(lotData)) {
              lotData = [];
            }
            if (isEmpty(prevData) || pageRefreshedRef.current) {
              return { total_count: res.total_count, data: lotData };
            }
            return {
              total_count: res.total_count,
              data: [...prevData?.data, ...lotData],
            };
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getAllMyPayments(params)
        .then((res) => {
          if (isEmpty(res.items)) {
            setLastRecordReached(true);
            return;
          }
          setMyPaymentList((prevData) => {
            let lotData = res.items;
            if (isEmpty(lotData)) {
              lotData = [];
            }
            if (isEmpty(prevData) || pageRefreshedRef.current) {
              return { total_count: res.total_count, data: lotData };
            }
            return {
              total_count: res.total_count,
              data: [...prevData?.data, ...lotData],
            };
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const hasMoreData =
    OFFSET_LIMIT * currentPage < (myPaymentList?.total_count || 0) &&
    !lastRecordReached;

  useEffect(() => {
    if (selectedTab === MY_BUYS_TABS.PAYMENTS) {
      getMyPaymentsInfo();
    }
  }, [currentPage]);

  const getPageUrl = () => {
    const myBuys = findWordInUrl(pathname, "my-buys");
    const myPayments = findWordInUrl(pathname, "my-payments");
    const urlPath = findWordInUrl(pathname, "bidder-mybuys");
    if (urlPath) {
      return PAGE_EVENTS.MY_TRANSACTION;
    }
    if (myBuys || myPayments) {
      return PAGE_EVENTS.MANDI_FILTERED;
    }
    return PAGE_EVENTS.HOME;
  };

  useEffect(() => {
    setCurrentPage(1);
    if (selectedTab === MY_BUYS_TABS.PAYMENTS) {
      setMyPaymentList({});
      getMyPaymentsInfo();
      tracker(MY_PAYMENTS_LISTING_PAGE_EVENTS.MY_PAYMENTS_LISTING_VIEWED, {
        Pageurl: getPageUrl(),
      });
    }

    if (selectedTab === MY_BUYS_TABS.MY_BUYS) {
      tracker(MY_BUYS_LISTING_PAGE_EVENTS.MY_BUYS_LISTING_VIEWED, {
        Pageurl: getPageUrl(),
      });
    }
  }, [selectedTab]);

  const verifyDateRange = (val) => {
    const { startDate, endDate } = val[0];
    const noOfDays = getDiffDays(startDate, endDate);
    trackUser("l_my_buys_filter_apply", {
      number_of_days: noOfDays,
    });
    // condition for max days allowed to select dates
    if (noOfDays <= settings.myBuysDiffDays) {
      setDates(val);
    } else {
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    setSelectedTab(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    getMyBuysInfo();
  }, [range, currentPage]);

  useEffect(() => {
    if (getSrcPage) {
      trackUser("l_visited_my_buys", {
        src: getSrcPage,
      });
    }
  }, [getSrcPage]);

  useEffect(() => {
    const myBuys = findWordInUrl(pathname, "my-buys");
    const myPayments = findWordInUrl(pathname, "my-payments");

    if (myBuys || myPayments) {
      setIsHeaderVisible(false);
    } else {
      setIsHeaderVisible(true);
    }
  }, [pathname]);

  const handleSelectedtab = (e, newVal) => {
    setSelectedTab(newVal);
  };
  const style = pathname.includes("my-payments");

  const handleDateClick = () => {
    setOpenDatepicker(!openDatepicker);
    !openDatepicker &&
      tracker(MY_BUYS_LISTING_PAGE_EVENTS.MY_BUYS_LISTING_DATE_FILTER_CLICK, {
        Pageurl: getPageUrl(),
      });
  };

  const trackEvents = () => {
    tracker(MY_BUYS_LISTING_PAGE_EVENTS.MY_BUYS_LISTING_DATE_FILTER_APPLY, {
      Pageurl: getPageUrl(),
    });
  };

  return (
    <StyledContainer className="bg-white">
      {isHeaderVisible && <Header title="my_transaction" />}
      {!!customerDetails?.length && isHeaderVisible && (
        <div className="py-[1rem] px-[1rem]">
          <OutstandingCard customerDetails={customerDetails} />
        </div>
      )}
      <ClickAwayListener onClickAway={() => setOpenDatepicker(false)}>
        <>
          <Box className="flex justify-center">
            <CustomTabs
              selectedTab={selectedTab}
              handleSelectedtab={handleSelectedtab}
              label={["my_buys", "my_payments"]}
            />
          </Box>
          {selectedTab === MY_BUYS_TABS.MY_BUYS && (
            <Box className={myBuysWrapper}>
              <div className={calendarDiv}>
                <div
                  onClick={() => {
                    handleDateClick();
                    trackUser("l_my_buys_filter_click");
                  }}
                >
                  <Text
                    textType={TEXT_TYPES.SECONDARY}
                    className={purchaseDateText}
                  >
                    {translate("purchase_date")}:
                  </Text>
                  <Text
                    className={selectedDateRange}
                    textType={TEXT_TYPES.SECONDARY}
                  >
                    {formatDateToDDMMYYYY(range.startDate)} -{" "}
                    {formatDateToDDMMYYYY(range.endDate)}
                  </Text>
                </div>
                <div>
                  <KeyboardArrowDownIcon fontSize="medium" />
                </div>
                {showLedgerDownloadBtn && <LedgerDownLoad />}
              </div>
              {!!openDatepicker && (
                <Box sx={styles} className={calendarBox}>
                  <DateRangepicker
                    onChange={(val) => {
                      verifyDateRange(val);
                      setOpenDatepicker(false);
                    }}
                    range={range}
                    trackEvents={trackEvents}
                  />
                </Box>
              )}
            </Box>
          )}
        </>
      </ClickAwayListener>
      <div>
        {selectedTab === MY_BUYS_TABS.MY_BUYS && (
          <>
            {loading ? (
              <Loader />
            ) : boughtDetails?.length > 0 ? (
              <div
                className={myBuysBox}
                style={{
                  paddingBottom: style ? "4.5rem" : "",
                }}
              >
                <Text textType="secondary" className="mb-4">
                  {translate("results")}
                </Text>
                {boughtDetails.map((item, index) => (
                  <div key={index} className="mb-4">
                    <Link
                      to={generatePath(path.details, {
                        date: getStartOfToday(item.auction_date),
                        id: item.mandi_id,
                      })}
                      state={{ source: state?.source || componentSource }}
                      onClick={() => {
                        trackUser("l_clicked_my_buys_cards", {
                          src: "my_buys",
                          mandi_name: item.mandi_name,
                        });
                      }}
                    >
                      <PurchaseCard data={item} />
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <EmptyScreen />
            )}
          </>
        )}
        {selectedTab !== MY_BUYS_TABS.MY_BUYS && (
          <div className="mt-2 mb-4 pl-5 pr-3 flex justify-between">
            <Text>{translate("transaction_history")}</Text>
            {showLedgerDownloadBtn && <LedgerDownLoad />}
          </div>
        )}

        {selectedTab !== MY_BUYS_TABS.MY_BUYS && (
          <>
            {loading ? (
              <Loader />
            ) : myPaymentList.data ? (
              <>
                <div className={myPaymentsBox}>
                  <InfiniteScroll
                    fetchData={getMyPaymentsInfo}
                    setPageNumber={setCurrentPage}
                    loading={loading}
                    hasMoreData={hasMoreData}
                  >
                    {myPaymentList.data?.map((pay, index) => {
                      return (
                        <div key={index}>
                          <MyPayments
                            data={pay}
                            index={index}
                            currentPage={currentPage}
                            getAllSplitPaymentsInfo={getAllSplitPaymentsInfo}
                            splitInfo={splitInfo}
                          />
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </>
            ) : (
              <EmptyScreen />
            )}
          </>
        )}
      </div>
      <AlertDialog
        open={openDialog}
        primaryBtnHandler={() => {
          setOpenDialog(false);
          setOpenDatepicker(true);
        }}
        content={translate("please_select_days_in_range_of_x", {
          x: settings.myBuysDiffDays,
        })}
      />
    </StyledContainer>
  );
};

export default MyBuys;
