import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/styles";
import { theme } from "styles/AtomicStyles";
import { initializeService } from "utils/SendError";
import store from "utils/store";

import VersionManager from "components/VersionManager";

import Router from "./routes";

initializeService();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <div data-testid="app" className="h-100">
          <VersionManager />
          <Router />
        </div>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
