import Apple from "assets/images/apple1.png";
import ArrowLeft from "assets/images/arrow-left.png";
import backBtn from "assets/images/back.png";
import Bank from "assets/images/Bank.png";
import BidderDefaultProfile from "assets/images/bidder-default-profile.png";
import bidderEmptyIcon from "assets/images/bidderEmptyIcon.png";
import biddingFooter from "assets/images/biddingFooter.png";
import KDCWatermark from "assets/images/blurred-kdc-logo.png";
import CartIcon from "assets/images/cart.svg";
import Cash from "assets/images/Cash.png";
import CatalogueCard from "assets/images/catalogue-card.svg";
import CheckCircle from "assets/images/check-circle.png";
import Chevron from "assets/images/chevron.svg";
import CircularSuccess from "assets/images/circular-success.png";
import CopyClipBoard from "assets/images/copyClipboard.png";
import Credit from "assets/images/credit.png";
import DefaultLotImage from "assets/images/default-lot-image.png";
import OverdueTime from "assets/images/delayIcon.svg";
import EyeClose from "assets/images/eye-close.png";
import EyeOpen from "assets/images/eye-open.png";
import ForwardBtn from "assets/images/forwardBtn.png";
import FruitX from "assets/images/fruitx.png";
import KDCLogo from "assets/images/kdc-logo.png";
import LedgerIcon from "assets/images/ledger.png";
import Logo from "assets/images/logo.png";
import LogoVector from "assets/images/logovector.png";
import Mango from "assets/images/mango1.png";
import MultiFruit from "assets/images/multifruit1.png";
import NoImage from "assets/images/no-results.png";
import Orange from "assets/images/orange-bg.png";
import PillButtonImg from "assets/images/pillButtonImage.png";
import PomegranateLarge from "assets/images/pomegranate-large.png";
import PomegranateSmall from "assets/images/pomegranate-small.png";
import Pomo from "assets/images/pomo1.png";
import RedStrip from "assets/images/redStrip.png";
import Rupees from "assets/images/rupee.png";
import SeenTickMark from "assets/images/seen.svg";
import ShareRoundIcon from "assets/images/shareRoundIcon.png";
import ShutMouthEmoji from "assets/images/shut-mouth-emoji.png";
import SoldPin from "assets/images/sold-pin.png";
import SoldIcon from "assets/images/sold-tag.png";
import SoldWaterMark from "assets/images/sold-watermark.png";
import TripleDots from "assets/images/triple-dot.png";
import TrophyIcon from "assets/images/trophy.png";
import Trumpet from "assets/images/trumpet.png";
import TVScreenImage from "assets/images/tv-screen-image.png";
import Upi from "assets/images/Upi.png";
import UPIimage from "assets/images/UPI_ICON.png";
import WarningCircles from "assets/images/warning-circle.png";
import WaterMelon from "assets/images/watermelon.png";
import Whatsapp from "assets/images/whatsapp.svg";
import WishlistTag from "assets/images/wishlist-tag.png";
import {
  IMG_BANK_COLOR,
  IMG_BANK_ICON,
  IMG_GPAY,
  IMG_PAYTM,
  IMG_PHONEPE,
  IMG_UPI,
} from "vg-library/assets";

const IconMapping = {
  backBtn,
  BidderDefaultProfile,
  bidderEmptyIcon,
  biddingFooter,
  CartIcon,
  CatalogueCard,
  CheckCircle,
  Chevron,
  CircularSuccess,
  Credit,
  ForwardBtn,
  KDCLogo,
  KDCWatermark,
  LogoVector,
  PillButtonImg,
  PomegranateLarge,
  PomegranateSmall,
  SeenTickMark,
  ShutMouthEmoji,
  SoldIcon,
  SoldPin,
  SoldWaterMark,
  TrophyIcon,
  Trumpet,
  TVScreenImage,
  WarningCircles,
  WaterMelon,
  WishlistTag,
  DefaultLotImage,
  NoImage,
  RedStrip,
  Whatsapp,
  CopyClipBoard,
  ShareRoundIcon,
  TripleDots,
  FruitX,
  EyeOpen,
  EyeClose,
  MultiFruit,
  Mango,
  Apple,
  Pomo,
  Rupees,
  Orange,
  Logo,
  ArrowLeft,
  Upi,
  Cash,
  Bank,
  UPIimage,
  IMG_BANK_COLOR,
  IMG_BANK_ICON,
  IMG_GPAY,
  IMG_PAYTM,
  IMG_PHONEPE,
  IMG_UPI,
  OverdueTime,
  LedgerIcon,
};

const ImageIcons = ({ name, ...restProps }) => {
  const icon = IconMapping[name];
  return <img src={icon} {...restProps} alt={name} />;
};

export default ImageIcons;
